import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardCmentarzDobre = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/dobre/cmentarz/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/dobre/cmentarz'}
      title={'Cmentarz'}
    >
      Zapraszamy na wirtualny spacer na cmentarzu parafialnym w Dobrem. Na
      zdjęciach między innymi mogiła Jana Zycha, zasłużonego dla pamięci
      Konstantego Laszczki kierownika szkoły w Dobrem.
    </MonumentCard>
  )
}

export default MonumentCardCmentarzDobre
