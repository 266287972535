import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'
import SEO from '../../../components/seo'
import MonumentCardCmentarzDobre from '../../../monuments/dobre/MonumentCardCmentarz'
import MonumentCardKosciolDobre from '../../../monuments/dobre/MonumentCardKosciol'
import MonumentCardMuzeumKonstantegoDobre from '../../../monuments/dobre/MonumentCardMuzeum'

const DobreArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Dobre" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Dobre</Header1>
        <MonumentCardMuzeumKonstantegoDobre />
        <MonumentCardCmentarzDobre />
        <MonumentCardKosciolDobre />
        <MonumentCardAsset
          title={
            'Dobre - dawne miasteczko w ziemi liwskiej, Rocznik Mińskomazowiecki tom 3, 1995-1996'
          }
          author={'Maria Danksza'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/Dobre_dawne_miasteczko_w_ziemi_liwskiej_RMM_r1995_1996_t3_s211_221.mp3'
          }
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default DobreArchive
