import React from 'react';
import MonumentCard from '../../components/MonumentCard/MonumentCard';
import { useStaticQuery, graphql } from 'gatsby';

const MonumentCardMuzeumKonstantegoDobre = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/dobre/muzeum/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/dobre/muzeum-konstantego-laszczki'}
      title={'Muzeum Konstantego Laszczki'}
    >
      Ponad pół wieku minęło od założenia pierwszej w powiecie mińskim placówki
      muzealnej. Starania podjęte w drugiej połowie lat 60 – tych ubiegłego
      stulecia doprowadziły do utworzenia Społecznego Muzeum Konstantego
      Laszczki w Dobrem. Wyeksponowane w nim przedmioty dokumentują twórczość
      jednego z najwybitniejszych polskich artystów XIX i XX wieku. Możliwość
      obejrzenia bogatego zbioru pamiątek po rzeźbiarzu urodzonym w 1865 roku w
      Makówcu Dużym wywołuje spore zainteresowanie. Wobec tego muzeum jest
      chętnie odwiedzane zarówno przez grupy wycieczkowe, jak i indywidualnych
      turystów.
    </MonumentCard>
  );
};

export default MonumentCardMuzeumKonstantegoDobre;
